.img-card{
    width: 100%;
  }

  .card {
  padding: 0px;
    min-height: 380px;
    border: 1px solid #D9D9D9;
    margin: 5px;
    position: relative;
}
.imagen {
  width: 100%;
  height: 297px;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 70%, 0 89%);
}


.imagen{
  width: 100%;
  height: 297px;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 70%, 0 89%); }

  h5.card-title.title-card.up-case {
    font-family: var(font-principal);
}

.imagen img {
width: 100%;
height: 100%;
object-fit: cover; /* Ajusta la imagen para abarcar todo el espacio del div sin deformarse */
object-position: center center; /* Centra la imagen horizontal y verticalmente en el div */
}
.right-curve.active-hover,
.left-curve.active-hover{
  box-shadow: 0px -8px 2px 0px var(--color-primary);
}
.btn-card.active-hover{
    background-color: none;
border-bottom: 1px solid var(--color-primary);
}
.title-card {
  margin-top: -40px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  padding: 0px 30px;
}
.btn-card {
  color: var(--color-primary);
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  background: none;
  border: none;
}
.swiper-slide.card-home {
  max-width: 375px;
}
.cards-show .card {
  max-width: 266px;
}
@media(max-width:400px){
  .swiper-slide.card-home {
    margin-bottom: 60px;
    max-width: 375px;
}
}