@import 'leaflet/dist/leaflet.css';
section.details-page {
    padding: 0px 100px;
}
.main-container .image-single {
    height: 500px;
}

.img-details {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.main-container .image-single {
    width: calc(80% - 10px);
    text-align: center;
    height: 700px;
    object-fit: cover;
}

.main-container {
    display: inline-grid;
    justify-content: initial;
    flex-wrap: wrap;
    margin: 20px 20px;
    gap: 68px;
    margin-bottom: 100px;
}

/* MAPA  */
.mapa {
    width: 99%;
    height: 500px;
}

.leaflet-container {
    height: 98%;
}



.image-single,
.description-info {
    flex: 1;
    max-width: 90%;
    /* Ajusta según tus necesidades */
}

textarea#description {
    border: 1px solid #b1a9a9;
    min-height: 126px;
}

select#value {
    WIDTH: 116px;
}

input#price {
    border: none;
    border-bottom: 1px solid var(--color-primary);
}

input#email-contact {
    border: none;
    border-bottom: 1px solid var(--color-primary);
    width: 100%;
}

input#phonenumber {
    border: none;
    border-bottom: 1px solid var(--color-primary);
    width: 100%;
}

h5 {
    font-size: 25px;
}

.descripcion {
    margin: 10px 0px;
    font-size: 17px;
    min-height: 150px;
    font-family: var(--font-principal);
    border-radius: 0px 38px 0px 40px;
    padding: 10px;
}
.accordion {
    margin: 20px 0px;
}
img.img-thumbnail {
    width: 116px;
}
.chat-window {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 250px;
    display: block; /* Oculto por defecto */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    z-index: 1000;
}

.chat-header {
    background-color: #25D366;
    color: white;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.chat-button {
    display: block;
    width: 100%;
    background-color: #25D366;
    color: white;
    text-align: center;
    padding: 15px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-top: 1px solid #ddd;
}

.chat-button:hover {
    background-color: #1EB354;
}
.details-page h2 {
    margin-top: 20px;
}