@import "bootstrap-icons/font/bootstrap-icons.css";

span.navbar-brand a {
  font-family: var(--font-principal);
  font-weight: 800;
  text-decoration: none;
  color: white;
}

.dropdown-menu.show a {
  color: black;
  font-weight: 400;
}

.dropdown-menu.show {
  display: grid;
}

button.navbar-toggler.collapsed {
  border: none;
  background: none;
}

a#basic-nav-dropdown {
  text-align: center;
  color: white;
}

.me-auto.navbar-nav {
  display: flex;
  margin-left: 40px;
  justify-content: space-between;
  align-items: center;
}

.me-auto.navbar-nav a {
  font-size: 13px;
  color: white;
  margin: 0px 5px;
  font-family: var(--font-principal);
  font-weight: 600;
  text-decoration: none;
}

a.dropdown-item {
  color: black !important;
}

.dropdown-menu.show a {
  padding: 10px;
  color: black;
  text-decoration: none;
}
.dropdown-menu.show a:hover {
  padding: 10px;
  color: white;
  background-color: var( --color-primary);
  text-decoration: none;
}
.navbar-toggler-icon {


  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

}

.navbar-toggler:focus {
  outline: none !important;
  text-decoration: none;
  outline: none !important;
  box-shadow: none;
}
nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
  background: blue !important;
}.dropdown a {
  width: 100%;
  display: block;
}
a.logo-frase {
  display: flex;
  align-items: center;
}

a.logo-frase p {
  font-weight: 200;
  margin: 0px;
}
span.navbar-brand {
  width: 300px;
  height: 50px;
}
.logo {
  width: 100%;
}
@media( max-width: 600px){
  a.logo-frase {
    display: block;
    text-align: center;
}
a.logo-frase p {
  font-size: 11px;
  margin: 0px;
}
}
.me-auto.navbar-nav a {
  position: relative;
  text-decoration: none;
  color: white;
}

.me-auto.navbar-nav a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px; /* Ajusta el grosor del borde */
  bottom: 0;
  left: 50%;
  background-color: white;
  transition: width 0.4s ease, left 0.4s ease;
}

.me-auto.navbar-nav a:hover::after {
  width: 100%;
  left: 0;
}
a.button-class.active-button {
  border-bottom: 2px solid white;
}