@font-face {
  font-family: bauhaus;
  src: url('../../public/assets/fonts/bauhaus-93.ttf') format('truetype');
  
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3{
font-family: bauhaus;
}

input:focus,
select:focus,
select,
input.form-control:focus {

  outline: none !important;

  outline-width: 0 !important;

  box-shadow: none;

  -moz-box-shadow: none;

  -webkit-box-shadow: none;
  border: none;
  border-bottom: 2px solid blue;

}
ul {
  display: block;
}
input#stock {
  border: none;
  border-bottom: 2px solid var(--color-primary);
}

body {
  margin: 0px;
  background-color: white;
  min-height: 600px;
}
a{text-decoration: none;}
label {
  font-family: var(--font-principal);
}

form {
  font-family: var(--font-principal);
}

input {
  font-family: var(--font-principal);
}

.row {
  min-height: 430px;
}

.spinner-loading {
  text-align: center;
}

.spinner-border.text-primary {
  width: 150px;
  height: 150px;
}

.principal {
  min-height: 800px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search.d-flex {
  padding: 0px;
  border: none;
  margin: 0;
}

.page-link {
  margin: 10px 10px;
  border: none;
}
h6 {
  font-size: 20px;
}
.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: var(--color-primary) !important;
}

p {
  margin: 0px;
}



nav {
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}



form {
  border: none;
  MARGIN-TOP: -30px;
  padding: 20px;
  background: white;
}

.img-film {
  width: 100%;
}

.list-genrs {
  display: block;
}

.favs-title {
  display: flex;
  align-items: center;
}


input[type=text],
select,
textarea {
  width: 100%;
  padding: 0px 12px;
  border: none;
  border-bottom: 1px solid var(--color-primary);
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 10px 0px;
  display: inline-block;
  width: 100%;
}

input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.inputs-location {
  display: flex;
  flex-wrap: wrap;
}


/* EDIT */

input#title {
  font-size: 28px;
  font-family: var(--font-principal);
  border-left: none;
  border-top: none;
  border-right: none;

}

input[type="text"]#title:focus {
  border-bottom: 2px solid blue;
  font-size: 28px;
  font-family: var(--font-principal);
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
}

._2c50p {
  background: white;
  margin: 0px;
  border: 1px solid var(--color-primary);
}

._2c50p {
  margin: 0px !important;
}

._3WRGR {
  stroke: var(--color-primary) !important;
  stroke-opacity: 1;
  stroke-width: 1px;
  fill: var(--color-primary) !important;
}

._19gvi {
  border: 1px solid var(--color-primary);
  opacity: 1;
}

.image-edit {
  position: relative;
  display: inline-block;
  /* Esto es importante para que el div se ajuste al tamaño de la imagen */
}

.delete-image {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  /* Ajusta el padding según sea necesario */
  background-color: rgba(255, 0, 0, 0.7);
  /* Color de fondo del botón */
  color: #fff;
  /* Color del texto del botón */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.addState {
  background: blue;
  color: white;
  font-family: 'Poppins';
  font-size: 17px;
  border: none;
  margin: 0px 10px;
  border: 2px solid black;
  margin: 20px 0px;
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 10px 4px 28px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 4px 28px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 4px 28px 0px rgba(0, 0, 0, 0.75);
}

button.addState:hover {
  background-color: white;
  color: blue;
  border: 2px solid blue;
  -webkit-box-shadow: 10px 4px 28px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 4px 28px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 4px 28px 0px rgba(0, 0, 0, 0.75);

}

section.agenda {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.button {
  text-align: left;
  margin: 26px 0px 0px 0%;

}

/* LOGIN  */
.container-login {
  width: 350px;
  height: auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 30px;
}

.container-login {
  text-align: center;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.7);
  min-height: 350px;
  border-radius: 10px;
  max-width: 422px;
  margin: 0 auto;
}
.container-login h2 {
  text-align: center;
  margin: 10px 0 30px 0;
  font-size: 28px;
  font-weight: 800;
}
.buttons {
  margin-top: 30px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  padding: 15px;
}

form.form-log {
  border-radius: 20px;
}

button.btn.btn-primary.btn-aux {
  background: none;
  color: black;
  border: none;
  text-decoration: underline;
}

h3.title-list {
  text-align: center;
  margin: 16px 18px;
}

.group-dates {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: start;
}

.fechas {
  border: 1px solid rgb(61, 145, 255);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 10px 10px 5px 0px rgba(191, 172, 172, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(191, 172, 172, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(191, 172, 172, 0.75);
}

button.delete-range {
  background: red;
  color: white;
  border: none;
  border-radius: 7px;
  margin: 7px;
}

section.agenda {
  margin: 26px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

section.map-agenda {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 18px;
}

.agenda {

  -webkit-box-shadow: 1px 5px 24px 0px rgba(134, 134, 134, 1);
  -moz-box-shadow: 1px 5px 24px 0px rgba(134, 134, 134, 1);
  box-shadow: 1px 5px 24px 0px rgba(134, 134, 134, 1);
  padding: 20px;
  border-radius: 20px;
}

button.contact-agenda {
  background: blue;
  color: white;
  padding: 9px;
  border: none;
  border-radius: 10px;
}

.switch div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .rdrCalendarWrapper {
    color: #000000;
    font-size: 9px !important;
  }

  .image-single,
  .description-info {
    flex: 1 1;
    max-width: 100% !important;
  }

  .switch {
    margin: 20px 0px;
  }

  button.addState {
    background: blue;
    color: white;
    font-family: 'Poppins';
    font-size: 11px !important;
  }
}
p.ver-details {
  text-align: end;
}
.mapa-edit {
  margin: 25px 0px;
}
.cards-show {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
input.crear {
  background: blue;
  font-size: 21px;
  padding: 10px 34px;
}

.principal {
  display: flex;
}

.rutas {
  width: 100%;
}
.main-content-home {
  width: 80%;
  padding: 10px;
}
.adds {
  width: 20%;
}

section.home-page {
  display: flex;
}

.edit-page .main-container .image-single {
  width: calc(80% - 10px);
  text-align: left;
  min-height: 149px;
  object-fit: cover;
}

section.edit-page,
section.create-page {
  padding: 0px 157px;
}
._t1897 {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  border: 0;
  border-image-width: 0;
  padding: 0;
  object-fit: contain !important;
  background-color: white;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
.create-page .main-container .image-single {
  width: calc(80% - 10px);
  text-align: left;
  height: 200px;
  min-height: 183px;
  object-fit: cover;
}
.edit-page .main-container .image-single {
  width: calc(80% - 10px);
  text-align: left;
  height: 300px;
  min-height: 149px;
  object-fit: cover;
}
@media (max-width: 600px) {
  section.details-page {
    padding: 0px 20px !important;
}
.main-container .image-single {
  width: 100% !important;
  text-align: center;
  height: auto !important;  
  object-fit: cover;
}
}



/* BANNER */

section.banner-main {
  min-height: 400px;
  background-image: url("../../public/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  filter: brightness(0.9);
  background-position: 0px -190px ; /* Posición en píxeles (horizontal vertical) */
}section.banner-main {
  display: flex;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
section.banner-main h1 {
  color: white;
  filter: brightness(1) !important;
  font-weight: 200;
}




button.btn.btn-primary {
  background-color: white;
  border-radius: 27px;
  box-shadow: 0px 2px 31px -6px rgba(108, 172, 228, 1);
}

.boton-filtro {
  display: flex;
  color: black;
  border-radius: 10px;
  justify-content: center;
}

p.filter-item {
  margin: 0px;
  margin-left: 5px;
  padding: 3px;
  font-size: 15px;
  font-weight: 500;
}

.boton-filtro .filter-item + .filter-item::before {
  content: " | ";
  margin-right: 5px;
}
.icon-search {
  display: inline-block;
  vertical-align: middle;
}
.icon-search {
  margin-right: -13px;
  margin-left: 5px;
  padding: 5px;
  padding-top: 11px;
  padding-left: 12px;
  padding-right: 15px;
  font-size: 16px;
  color: white;
  background: #015089;
  border-radius: 21px;
  margin-bottom: -8px;
  margin-top: -10px;
}
.search-form {
  border: none;
  MARGIN-TOP: 0px;
  padding: 20px;
  background: white;
}


ul.dept-ul {
  display: inline-flex;
  margin: 0px;
  padding: 0px;
}

ul.dept-ul li {
  margin: 0px 0px 0px 0px;
  list-style: none;
}



/* search page  */
section.search-page {
  display: flex;
  flex-wrap: wrap;
}
section.list-category-page {
  margin: 20px;
}
.botton-search-categorypage {
  text-align: center;
  margin: 30px;
}
section.list-prop {
  width: 55%;
}

.list-props {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content:flex-start;
  gap: 5px;
  align-items: center;
}
section.logged {
  margin: 39px 100px;
}

ul.dept-ul li {
  margin: 0px 3px 3px 4px;
  list-style: none;
}



h2.title-search {
  font-size: 26px;
}
span.localidad {
  font-size: 27px;
  color: var(--color-primary);
}


/* css mapa  */

.map-search {
  margin-top: 50px;
  width: 37%;
  height: 100% !important;
}

.img-marker {
  width: 200px;
  height: 150px;
}
.img-marker img{
  width: 100% !important;
  height: 100%;
}
h5.title-marker {
  font-size: 20px;
}p.price-marker {
  font-size: 18px;
}.ver-mas.div-btn a {
  font-size: 19px;
}


button.pagination {
  background: none;
  color: blue;
}

button.pagination-active.pagination {
  background: blue;
  color: white;
  border-radius: 50%;
}

button.pagination-button {
  background: blue;
}
button.btn.btn-primary {
  background: blue;
}
.regiones-div {
  width: 100%;
  height: 300px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
button.buscador.btn.btn-primary {
  background: white;
}
h3.title-regions a {
  text-decoration: none;
  color: white;
  font-weight: 400;
}

a.titulos-con-propiedades {
  color: black;
  text-decoration: none;
  font-weight: 300;
}
.regiones-div {
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px;
}
.paginatio-search {
  background: none !important;
  color: black !important;
}

@media(max-width :820px) {
  section.map-agenda {
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    gap: 18px;
  }
  .rdrDateRangeWrapper {
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.rdrMonth {
  width: 100% !important;
}
}
@media(max-width :820px) {
  section.home-page {
    display: block;
}
.main-content-home {
  padding: 10px;
  width: 100%;
}
p.filter-item {
  font-size: 9px;
  font-weight: 500;
  margin: 0 0 0 5px;
  padding: 3px;
}
section.banner-main {
  align-items: center;
  background-position: 0 -190px;
  background-repeat: no-repeat;
  background-size: auto !important;
}
section.search-page {
  display: block !important
}
section.list-prop {
  width: 100% !important;
}
.map-search {
  margin-top: 50px;
  width: 100% !important;
  height: 100% !important;
}
.adds {
  width: 100%;
}
section.logged {
  margin: 39px 0px;
}section.edit-page, section.create-page {
  padding: 0px 14px;
}
}

@media(max-width :532px) {
  .card-search {
    width: 100%;
    max-width: 232px !important;
    margin-bottom: 0px;
    margin: 10px 10px;
    min-height: 338px;
    transition: transform 0.3s ease;

}
}
@media (max-width: 404px) {
  .card-search {
      width: 100%;
      max-width: 100% !important;
      height: 350px !important;
      margin-bottom: 0px;
      margin: 10px 10px;
      min-height: 338px;
      transition: transform 0.3s ease;
  }
}
.regiones-div {
  /* Otros estilos que necesites */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Estilos adicionales para la clase active-region */
.active-region {
  transform: scale(1.1); /* Aumenta el tamaño del div al 110% */
  box-shadow: 0 4px 8px rgba(13, 13, 13, 0.37);
  border-radius: 20px; /* Agrega una sombra para un efecto más agradable */
}

.seccion-regiones {
  margin: 20px 0px;
  padding: 20px 0px;
}.dropdown-menu.show {
  display: block !important ;
}
section.loading-home {
  margin: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.logo{

  width: 50px;
}
button.accordion-button.collapsed {
  padding: 0px !important;
}
h2.accordion-header {
  margin: 0px;
}
button.btn.btn-primary.btn-form{
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: wheat;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

button.btn.btn-primary.btn-form:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}button.btn.btn-primary.btn-form:active {
  transform: translateY(-1px);
}
.contenedor-recaptcha iframe {
  width: 250px;
}