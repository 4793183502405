footer{
    background-color: white;
    color: black;
margin-top: 30px;
padding: 20px 0px;
}
footer nav {
    color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
}