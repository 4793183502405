.modal-content {
    border-radius: 10px;
  }
  
  .modal-header {
    background-color: var(--first-color);
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .modal-title {
    font-size: 24px;
  }
  
  .modal-body {
    padding: 20px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
  
  /* Formulario */
  .search-form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="search"], 
  input[type="number"], 
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  input[type="search"]::placeholder {
    color: #888;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
  }
  
  .price-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .field {
    flex: 1;
  }
  button.btn.btn-primary {
    background: blue;
}