
.card-search {
  width: 100%;
  max-width: 240px;
  margin-bottom: 0px;
  margin: 10px 10px;
  min-height: 345px;
  transition: transform 0.3s ease;
}
.card-search:hover {
  transform: translateY(-10px);
}
.image-card{
  width: 100%;
  height: 210px;

  border-radius: 22px;
}
img.img-card-search {
  width: 100%;
  height: 100%;
object-fit: cover; 
object-position: center center; 
border-radius: 22px;
}
a.link {
  text-decoration: none;
}
.contenido {
  padding: 0px 10px;
}
h5.card-title {
  color: var(--color-primary);
  font-weight: 600;
  font-family: var(--font-principal);
  font-size: 17px;
}
a.link.link-info {
  color: black !important;
}
p.btn-search {
  color: var(--color-primary);
  text-decoration: underline;
}


/* EDICION */
.btn-edit-watch {
  display: flex;
  justify-content: space-between;
}
p.btn-edit {
  color: red;
  text-decoration: underline;
  font-weight: 600;
}
a.link.ver-info.link-info p {
  font-weight: 700;
}
i.bi.bi-trash {
  color: black;
  transition:color 1s ease 0s;

}
i.bi.bi-trash:hover {
  color: white;
  transition:color 1s ease 0s;
}

p.card-owner {
  background: #1414a0;
  padding: 0px;
  margin: 00;
  text-align: center;
  border-radius: 12px;
  color: white;
  padding: 5px;
  overflow :hidden;
}
.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background-color: #fdf2f287;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
  outline: 1px solid #ff0000;
  transition: outline 0.75s ease 0s, background-color 1s ease 0s;


}
button.delete-button:hover {
  outline: 10px solid red;
  background-color: red;

}
.image-card {
  position: relative;
}
@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.deleting {
  animation: flash 1s infinite;
}
.image-card {
  min-height: 290px;
  width: 100%;
}
img.image-card-search {
  width: 100%;
  height: 100%;
}
.swiper.galery-search {
  border-radius: 20px;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper.galery-search {
  height: 230px;
}