button.unlog {
    background: none;
    border: none;
    color: red;
    font-weight: 500;
    text-decoration: underline;
}
button.unlogged {
    color: red;
    border: none;
    background: none;
}