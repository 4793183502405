/* Estilos para el contenedor de la tabla */
.table-container {
    overflow-x: auto;
    margin: 20px 0;
}

/* Estilos para la tabla de usuarios */
.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
}

.user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.user-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
}

.user-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-table tr:hover {
    background-color: #ddd;
}

.edit-button {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.edit-button:hover {
    background-color: #45a049;
}

/* Estilos para la paginación */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: #45a049;
}